<template>
    <div
        v-loading="loading"
        element-loading-background="rgba(122, 122, 122, 0.1)"
        element-loading-text="Please wait"
        :element-loading-spinner="svg"
        element-loading-svg-view-box="-10, -10, 50, 50"
        style="z-index: 1"
    >
        <div class="card card-policy-assigned">
            <SingleLineFilter 
                is-first-dates
                is-show-year-only
                is-show-sales-options
                is-show-leads-options
                show-second-line
                is-second-policy-sales-agent
                is-second-lead-sources
                show-second-export
                show-second-reset
                link-type="dashboard-details-report-export"
                @reset="resetFilters"
            />

            <TableModels 
                :data="store.getters.getDashboardDetail(data.data)"
                :data-headers="dashboard_details"
                show-table-total
                :table-total="data.total"
                table-message=""
                :loading="tableLoad"
                :srs="srs"
                show-footer
                :other-data="data"
            />
        </div>
    </div>
</template>

<script setup>
import SingleLineFilter from '@/views/NEW/Reports/Models/SingleLineFilter.vue'
import TableModels from '@/views/NEW/Reports/Models/TableModel.vue'
import { computed, onMounted, ref, watch } from 'vue'
import { dates, rFilters } from '@/store/composable/Reports'
import { paginationsDef } from '@/core/utils/DefaultData'
import moment from 'moment'
import { dashboard_details } from '@/core/utils/Tables'
import { useListing } from '@/store/composable/Listing'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'


const store = useStore()
const route = useRoute()
const loading = ref(false)
const tableLoad = ref(true)
const data = ref(paginationsDef)

onMounted(() => {
    resetFilters()
})

const srs = computed(() => {
    return (rFilters.value.page - 1) * rFilters.value.per_page
})

const headers = computed(() => {
    return dashboard_details
            .filter(x => x.value != 'policy_price')
})

watch(() => rFilters.value, async(filter) => {
    await listing()
}, { deep: true })

async function listing() {
     if(rFilters.value.page) {
        loading.value = true

        window.scrollTo({ top: 0, behavior: 'smooth' })

        const response = await useListing(rFilters.value, route.name)
        loading.value = false
        tableLoad.value = false
        data.value = response.data.data.report
    }
}

function resetFilters() {
    let start = moment().format('YYYY-MM-DD')
    let end = moment().format('YYYY-MM-DD')
    let sales = 'all'
    let leads = 'total-new-leads'

    if(route.query) {
        if(route.query.start_date) start = route.query.start_date
        if(route.query.end_date) end = route.query.end_date
        if(route.query.sales) {
            sales = route.query.sales
            leads = 'all'
        }
        if(route.query.leads) {
            sales = 'all'
            leads = route.query.leads
        }
    }

    
    dates.value = [start, end]
    rFilters.value = {
        agent: 'all',
        end_date: end,
        lead_source: "all",
        leads,
        page: 1,
        per_page: 50,
        sales,
        start_date: start,
        year: ''
    }
}

</script>
